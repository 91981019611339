import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
* , body{
  box-sizing: border-box;
}
body, html {
  min-width: 100%;
  min-height: 100vh;
  margin: 0;
}
html {
  background-repeat: no-repeat, repeat;
  background-image: linear-gradient(to bottom, hsla(210, 20%, 98%, 67%), hsla(210, 20%, 96%, 67%));
  /* background-image: radial-gradient(circle at 55% 1%, #303335, #212427 117%), url(./noise.png); */
}
body {
  font-family: Monorale, Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", Roboto, YuGothic, "游ゴシック", Meiryo, "メイリオ", sans-serif;
  color: hsl(0, 0%, 50%);
  line-height: 1.5;
}
#root {
  height: 100vh;
  overflow-y: hidden;
}
`;

export const Label = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #8b9196;
  margin: 0;
  padding: 0;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0px;
  row-gap: 20px;
  height: auto;
  align-items: center;
`;



export default GlobalStyle;
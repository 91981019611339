import React, { useState, useEffect, useCallback, FC } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {Icon, PageHeader, AlertBar, Button, ButtonWithIcon, useModal, Label, Content, FilterDropdown } from 'scorer-ui-kit';
import format from 'date-fns/format';
import { dateFormat } from '../utils/index';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';
import DateInput from '../components/DateInput';
import { DATE_FORMAT, DATE_FORMAT_WITH_SEC } from '../constants';
import { useOnRefreshAlert } from 'hooks/useOnRefreshAlert';
import { getLastExport, getExportTable, getCamList} from 'services/apiConfig';
import moment from 'moment';

const Container = styled(Content)`
  // @media screen and (min-width: 1920px) {
  //   padding-left: 168px;
  //   padding-right: 168px;
  // }
`;

const EmptyDiv = styled.div<{divHeight: number}>`
  height: ${({divHeight}) => divHeight}px;
`;

const HeaderContainer = styled.div`
  margin-bottom: 47px;
  > div:first-child > div > h1 {
    max-width: 650px;
    overflow-wrap: break-word;
    white-space: break-spaces;
    min-width: 200px !important;
  }
  & > div > div > div:nth-child(3){
    bottom: 4px;
  }
  & > div > p {
    font-weight: normal;
    font-style: normal;
  }
  position: relative;
  max-width: 630px !important;
`;

const InnerContainer = styled.div`
  margin-left: 1px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  column-gap: 9px;
  align-items: center;
  margin-bottom: 10px;
`;

const SubTitle = styled.label`
  font-size: 16px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  color: #5a6269;
  margin: 0;
  padding: 0;
`;

const StyledText = styled.div`
  color: hsl(207,5%,57%);
  font-size: 14px;
  font-weight: 500;
  margin-top: 2px;
`;


const FlexColumn = styled.div`
  display: flex;
  flex-direction: row;
  margin: 9px 0 41px 2px;
`;


const ProgressingContainer = styled.div`
  margin-top: 84px;
  padding-right: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SpinnerBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 12px solid #fff;
  border-right: 12px solid #fff;
  border-bottom: 12px solid #fff;
  border-left: 12px solid #a6e5fa;
  background: transparent;
  width: 64px;
  height: 64px;
  border-radius: 50%;
`;

const ProcessingBox = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  color: #5a6269;
  margin-top: 22px;
`;

const SubHeadingTxt = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 17px 0;
`;

const ProcessingBoxSubHeading = styled.p`
  font-size: 14px;
  max-width: 381px;
  font-style: italic;
  line-height: 1.79;
  text-align: center;
  color: #8b9196;
  margin-top: 15px;
  margin-bottom: 0px;
`;

const TickIconBox = styled.div`
  width: 64px;
  height: 64px;
  padding-top: 3px;
  border-radius: 50%;
  background-color: #87d58f;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SuccessErrorContainer = styled.div`
  margin-top: 84px;
  padding-right: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TickIconBoxDanger = styled.div`
  width: 64px;
  height: 64px;
  border-radius:50%;
  background-color: #de6b6b;
  display:flex;
  justify-content:center;
  align-items: center;
`;

const ButtonDivContainer = styled.div`
`;


const ButtonContainer = styled.div`
  margin-top: -13px;
  margin-left: 2px;
  display: flex;
  gap: 10px;
`;

const AlertBarContainer = styled.div`
  width: 516px;
  margin: 10px 0px;
`;

const DividerTop = styled.div`
  margin-bottom: 47px;
  width: 60%;
  height: 1px;
  background-color: #eee;
`;

const DividerBottom = styled.div`
  margin-top: 43px;
  margin-bottom: 7px;
  width: 60%;
  height: 1px;
  background-color: #eee;
`;

const OtherOptionBox = styled.p`
  font-size: 14px;
  font-style: italic;
  line-height: 1.79;
  text-align: center;
  color: #8b9196;
  margin-top: 11px;
`;

const NewExportBox = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 278px;
  margin-top: -25px;
`;

const NewExportText = styled.p`
  font-size: 14px;
  line-height: 1.79;
  color: #4699d4;
  cursor: pointer;
`;

const DotContainer = styled.div`
  width: 25px;
  text-align: center;
  font-weight: bold;
  padding-top: 10px;
`;

const LastExportBox = styled.div`
`;

const LastExportText = styled(Label)`
  font-size: 20px;
`;

const DateShowBox = styled.div<{ marginTop?: string }>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ marginTop }) => marginTop};
`;

const DateTitle = styled.div`
  font-size: 12px;
  margin: 0;
`;

const DateShowResult = styled(Label)`
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 16px;
  margin-bottom: 0;
`;
  

const DateBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 2px;
`;

const LastButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  & > button:first-child{
    margin-right: 10px;
    background: #e4edf4;
  }
`;

const LastBox = styled.div`
  margin-top: 8px;
  position: absolute;
  right: 10px;
  top: 21px;
  button {
    background: #e4edf4;
  }
`;

const ButtonFormat = styled(Button)`
  &:focus {
    outline: 2px solid #838383;
  }
`;

const ButtonWithIconFormat = styled(ButtonWithIcon)`
  &:focus {
    outline: 2px solid #838383;
  }
`;


const HeaderButton = styled.div`
  & > button:first-child {
    background: #e4edf4;
  }
`;

const BackLinkNonHover = styled.label`
  font-family: ${({ theme }) => theme.fontFamily.ui}; 
  font-size: 12px;
  font-weight: 500;
  color: #7c8793;
  margin-bottom: 4px;
  line-height: 12px;
  text-decoration: none;
`;



const PageHeaderFormatter = styled.div`
  svg {
    margin-top: -7px;
  }
`;

const DataSourceContainer = styled.div`
  margin-top: 30px;
`;


interface IInterval {
  start: Date,
  end: Date
}

interface IQueryParam {
  startDate?: string;
  endDate?: string;
}

interface ILastExport {
  cam_list?: string;
  created_date?: string;
  start_date?: string;
  end_date?: string;
  sources?: string;
  oldest_image_time?: string;
}



const Export: FC = () => {
  const history = useHistory();
  const params = useLocation().search;
  const { goBack } = useHistory<string>();
  const { t } = useTranslation(['CommonDict']);
  const { setContinueShow } = useOnRefreshAlert();
  const { createModal, setModalOpen } = useModal();

  const [exporting, setExporting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isExportError, setIsExportError] = useState(false);
  const [alert, setAlert] = useState<IAlert | null>(null);
  const [historyParams] = useState<string>(params);
  const [lastExportData, setLastExportData] = useState<ILastExport>({});
  const [csvFile, setCsvFile] = useState<string>('');
  const [csvFileName, setCsvFileName] = useState<string>('export.csv');
  const [allowExport, setAllowExport] = useState<boolean>(true);  
  const [camList, setcamList] = useState([]);
  const [selectedCamList, setSelectedCamList] = useState([{'text': '', 'value': ''}]);

  const startDate = dateFormat(new Date());
  startDate.setDate(startDate.getDate() - 1);
  const [defaultDate,] = useState<IInterval>({ start: startDate, end: dateFormat(new Date()) });
  const [initialStartDate, setInitialStartDate] = useState<Date>(defaultDate.start);
  const [initialEndDate, setInitialEndDate] = useState<Date>(defaultDate.end);


  // ------------ select camera ------------
  const handleSelectCam = useCallback((e) => {
    setSelectedCamList(e);
    setAllowExport(e ? true: false);
  }, []);
  const fetchCamList = useCallback(async () => {
    try {
      const response = await getCamList();
      setcamList(response.data.data);
      setSelectedCamList(response.data.data);
    }
    catch (error) {
      console.error(error);
    }
  }, []);
  useEffect(() => {
    fetchCamList();
  }, [fetchCamList]);
  // ------------

  const getHistoryParams = useCallback(() => {
    const queryParams = new URLSearchParams(historyParams);
    const newParams: IQueryParam = {};
    newParams.startDate = queryParams.get('fromDate') || '';
    newParams.endDate = queryParams.get('toDate') || '';
    if (newParams.startDate !== '') {
      setInitialStartDate(new Date(newParams.startDate));
    }
    if (newParams.endDate !== '') {
      setInitialEndDate(new Date(newParams.endDate));
    }
  }, [historyParams]);

  useEffect(() => {
    getHistoryParams();
    return () => {};
  }, [getHistoryParams]);

  const updateStartDate = useCallback((date) => {
    if (initialEndDate.getTime() - 60000 <= date.getTime() || date > dateFormat(new Date())) {
      setAlert({
        key: new Date(),
        message: t('Please select \'From\' date smaller then now date and \'To\' date. '),
        type: 'error'
      });
      return;
    }
    setInitialStartDate(date);
  }, [initialEndDate, t]);

  const updateEndDate = useCallback((date) => {
    if (initialStartDate >= date || date > dateFormat(new Date())) {
      setAlert({
        key: new Date(),
        message: t('Please select \'To\' date smaller then now and greater then \'From\' date. '),
        type: 'error'
      });
      return;
    }
    setInitialEndDate(date);
  }, [initialStartDate, t]);

  const fetchLastExport = useCallback(async () => {
    try {
      const {data: { data }} = await getLastExport();
      setLastExportData(data);
    }
    catch (error) {
      console.error(error);
      setLastExportData({});
    }
  }, []);

  useEffect(() => {
    fetchLastExport();
    return () => {};
  }, [fetchLastExport]);


  useEffect(() => {
    setContinueShow(!(exporting && loading) && ( defaultDate.start !== initialStartDate || defaultDate.end !== initialEndDate));
    return () => {};
  }, [setContinueShow, exporting, loading, defaultDate, initialStartDate, initialEndDate]);



  // -----last Export-----
  const onDownloadLast = useCallback(async() => {
    if (lastExportData.start_date && lastExportData.end_date){
      setLoading(true);
      setExporting(true);
      const payload = {
        cam_list: lastExportData.cam_list,
        start_date: lastExportData.start_date,
        end_date: lastExportData.end_date,
      };
      try {
        const response = await getExportTable(payload);
        if(response.status === 200) {
          const link = document.createElement('a');
          const csvUrl = response?.data?.data;
          link.href = csvUrl;
          link.download = response?.data?.fileName;
          link.click();
  
          setCsvFile(response?.data?.data);
          setCsvFileName(response?.data?.fileName);
          setIsExportError(false);
          setLoading(false);
        } else {
          setLoading(false);
          setIsExportError(true);
        }
      }
      catch {
        setLoading(false);
        setIsExportError(true);
      }
    }
  }, [lastExportData]);


  // -----new Export-----
  const onDownloadCSV = useCallback(async() => {
    if (csvFile !== null) {
      const link = document.createElement('a');
      link.href = csvFile;
      link.download = csvFileName;
      link.click();
    }
  },[csvFile, csvFileName]);

  const onStartExport = useCallback(async () => {
    setLoading(true);
    setExporting(true);
    const payload = {
      start_date: format(initialStartDate, DATE_FORMAT_WITH_SEC),
      end_date: format(initialEndDate, DATE_FORMAT_WITH_SEC),
      cam_list: selectedCamList.map(item => item.text).join(','),
    };
    try {
      const response = await getExportTable(payload);
      if(response.status === 200) {
        setCsvFile(response?.data?.data);
        setCsvFileName(response?.data?.fileName);
        setIsExportError(false);
        setLoading(false);
      } else if(response.status === 202){
        setLoading(false);
        setIsExportError(true);
        setAlert({
          key: new Date(),
          message: t('File size limit exceeded ')+response.data.message+t('GB. Please select a smaller date range.'),
          type: 'error'
        });
      } else {
        setLoading(false);
        setIsExportError(true);
      }
    }
    catch {
      setLoading(false);
      setIsExportError(true);
    }
  }, [selectedCamList, initialStartDate, initialEndDate, t]);




  const handleNewExport = useCallback(() => {
    setExporting(false);
    setLoading(false);
    setIsExportError(false);
  }, []);

  const returnDashboard = useCallback(() => {
    history.push('/cameras');
  },[history]);

  const lastExport = useCallback(() => (
    <LastExportBox>
      <LastExportText htmlFor='' labelText={t('Last Export')} />
      <DateBox>
        <DateShowBox>
          <DateTitle>{t('Export Created') + ':'}</DateTitle>
          <DateShowResult htmlFor='' labelText={format(new Date(lastExportData.created_date as string), DATE_FORMAT)} />
        </DateShowBox>
        <DateShowBox style={{'marginRight': '28px'}}>
          <DateTitle>{t('Range') + ':'}</DateTitle>
          <DateShowResult htmlFor='' labelText={format(new Date(lastExportData.start_date as string), DATE_FORMAT) + ' - ' + format(new Date(lastExportData.end_date as string), DATE_FORMAT)} />
        </DateShowBox>
      </DateBox>
      <LastButtonBox>
        <ButtonFormat autoFocus id='CancelButton' design='secondary' onClick={() => setModalOpen(false)}>{t('Close')}</ButtonFormat>
        <ButtonWithIconFormat tabIndex={0} design='primary' icon='Download' position='left' onClick={onDownloadLast}>{t('Download')}</ButtonWithIconFormat>
      </LastButtonBox>
    </LastExportBox>
  ), [setModalOpen, t, lastExportData, onDownloadLast]);

  const onClickLastExport = useCallback(() => {
    createModal({
      isCloseEnable: true,
      width: '580px',
      padding: true,
      closeText: t('CLOSE'),
      customComponent: lastExport()
    });
  }, [createModal, lastExport, t]);

  const onClickCancel = () => {
    goBack();
  };

  return (
    <Container>
      <HeaderContainer>
        {window.location.href.endsWith('/export') &&
          <BackLinkNonHover>{t('Export Service')}</BackLinkNonHover>}
        <PageHeaderFormatter>
          <PageHeader
            title={t('New Export')}
            icon='Usage'
            introductionText={!exporting ? t('Creating a new export will allow you to download an archive containing your analysis logs and media data for the query provided below.') : ''}
            updateDocTitle={false}
          />
        </PageHeaderFormatter>
        {!exporting &&
          <LastBox>
            <Button design='secondary' disabled={Object.keys(lastExportData).length === 0} onClick={onClickLastExport} size='small'>{t('Last Export')}</Button>
          </LastBox>}
      </HeaderContainer>
      <InnerContainer>
        {
          !exporting &&
            <>
              {alert &&
                <AlertBarContainer>
                  {}
                  <AlertBar message={t(alert?.message)} type={alert?.type} />
                </AlertBarContainer>}
              <DataSourceContainer>
                <FlexContainer>
                  <SubTitle>{t('Data Source')}</SubTitle>
                </FlexContainer>
                <FilterDropdown
                  buttonIcon='MetaCategories'
                  buttonText={selectedCamList ? selectedCamList.map(item => item.text).join('，　') : ''}
                  list={camList}
                  loadingText='ローディング...'
                  maxDisplayedItems={6}
                  onSelect={(e) => handleSelectCam(e)}
                  optionType='checkbox'
                  selected={selectedCamList}
                />
              </DataSourceContainer>
              <br />
              <br />
              <FlexContainer>
                <SubTitle>{t('Time Range')}</SubTitle>
                <StyledText>  （{t('Date of oldest image')}：</StyledText>
                <StyledText>{moment(lastExportData.oldest_image_time).format('YYYY-MM-DD HH:mm:ss')}）</StyledText>
              </FlexContainer>
              <FlexColumn>
                <DateInput labelText='From' date={initialStartDate} callBackFunction={updateStartDate} />
                <DateInput labelText='To' date={initialEndDate} callBackFunction={updateEndDate} />
              </FlexColumn>
              <ButtonContainer>
                <Button disabled={!allowExport} onClick={onStartExport}>{t('Start Export')}</Button>
                {!window.location.href.endsWith('/export') ?
                  <HeaderButton>
                    <Button design='secondary' onClick={onClickCancel}>{t('Cancel')}</Button>
                  </HeaderButton>:
                  ''}
              </ButtonContainer>
              <EmptyDiv divHeight={window.innerHeight-628} />
            </>
        }
        {
          exporting && loading &&
            <ProgressingContainer>
              <DividerTop />
              <SpinnerBox>
                <Spinner />
              </SpinnerBox>
              <ProcessingBox>
                {t('Now Exporting …')}
              </ProcessingBox>
              <SubHeadingTxt>
                <ProcessingBoxSubHeading style={{'marginTop': '35px'}}>
                  {t('Please wait while we process your export. Once this has completed you will be able to download your exported data.')}
                </ProcessingBoxSubHeading>
              </SubHeadingTxt>
              <DividerBottom />
            </ProgressingContainer>
        }
        {
          exporting && !loading &&
            <SuccessErrorContainer>
              {
                !isExportError &&
                  <>
                    <DividerTop />
                    <TickIconBox>
                      <Icon weight='regular' icon='Success' color='inverse' size={36} />
                    </TickIconBox>
                    <ProcessingBox>
                      {t('Export Completed')}
                    </ProcessingBox>
                    <SubHeadingTxt>
                      <ProcessingBoxSubHeading>
                        {t('You can download this export data using the Download Export button below.')}
                      </ProcessingBoxSubHeading>
                    </SubHeadingTxt>
                    <ButtonDivContainer>
                      <ButtonWithIcon design='primary' icon='Download' position='left' size='small' onClick={onDownloadCSV}>{t('Download Export')}</ButtonWithIcon>
                    </ButtonDivContainer>
                    <DividerBottom />
                    <OtherOptionBox>
                      {t('Other Options')}{':'}
                    </OtherOptionBox>
                    <NewExportBox>
                      <NewExportText onClick={handleNewExport}>
                        {t('Start New Export')}
                      </NewExportText>
                      <DotContainer>.</DotContainer>
                      <NewExportText onClick={returnDashboard}>
                        {t('Return To Dashboard')}
                      </NewExportText>
                    </NewExportBox>
                  </>
              }
              {
                isExportError &&
                  <>
                    {alert &&
                      <AlertBarContainer>
                        <AlertBar message={alert?.message} type={alert?.type} />
                      </AlertBarContainer>}
                    <br />
                    <DividerTop />
                    <TickIconBoxDanger>
                      <Icon weight='regular' icon='Warning' color='inverse' size={36} />
                    </TickIconBoxDanger>
                    <ProcessingBox>
                      {t('Error Occured')}
                    </ProcessingBox>
                    <SubHeadingTxt>
                      <ProcessingBoxSubHeading>
                        {t('An error occurred during export')}
                      </ProcessingBoxSubHeading>
                    </SubHeadingTxt>
                    <DividerBottom />
                    <OtherOptionBox>
                      {t('Other Options')}{':'}
                    </OtherOptionBox>
                    <NewExportBox>
                      <NewExportText onClick={handleNewExport}>
                        {t('Start New Export')}
                      </NewExportText>
                      <DotContainer>.</DotContainer>
                      <NewExportText onClick={returnDashboard}>
                        {t('Return To Dashboard')}
                      </NewExportText>
                    </NewExportBox>
                  </>
              }
            </SuccessErrorContainer>
        }
      </InnerContainer>
    </Container>
  );
};

export default Export;
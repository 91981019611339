import axios from 'axios';
import React, { FC, useCallback, useReducer, useState, useEffect, useRef, useContext } from 'react';
import { LineSetContext, LineReducer, Spinner, LineUI, useMediaModal, usePoll, useClickOutside } from 'scorer-ui-kit';
import { LINE_VIEWER_CAMERA_IMAGE_REFRESH_INTERVAL, EDGE_API_BASE_URL } from '../../constants';
import { ILineViewer } from './types';
import { useTranslation } from 'react-i18next';
import { useWebsocketFrame } from 'hooks/useWebsocketFrame';
import styled, { css } from 'styled-components';
import i18n from 'i18n';
import { IVector2 } from 'scorer-ui-kit/dist/LineUI';
import { isEqual, cloneDeep } from 'lodash';
import AwaitingStreamEn from '../../svg/img_awaiting_stream_en.jpg';
import AwaitingStreamJp from '../../svg/img_awaiting_stream_jp.jpg';
import BoxModal from 'components/BoxModal';
import { MyContext } from 'pages/CameraDetails';

const Container = styled.div<{width: string, height: string}>`
  width: ${({width}) => width};
  height: ${({height}) => height};
  display: flex;
  flex-direction: column;
`;

const SpinnerContainer = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  height: 100%;
  min-height: 304px;
  border-radius: 3px;
  ${({ backgroundColor }) => css`
    background-color: ${backgroundColor? backgroundColor: '#a6a6a6'}
  `};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
`;

//pointer event commented for temporary
const LineUIImageWrapper = styled.div<{isClickable: boolean, isLineConfigurable: boolean}>`
  width: 100%;
  height: inherit;
  position: relative;
  ${({isLineConfigurable}) => !isLineConfigurable && css`
    // pointer-events: none;
  `}
  ${({isClickable}) => isClickable && css`
    // pointer-events: unset;
    cursor: pointer;
  `}
  & > div {
    height: inherit;
    > img {
      max-height: 80vh;
    }
  }
  background-color: hsl(202deg 15% 91%);
  svg > g > g > line:nth-child(2){        // Tien
    stroke: hsla(0,80%,45%,100%);
  }
`;

// const ToolContainer = styled.div<{isActive: boolean}>`
//   display: flex;
//   align-items: center;
//   gap: 0 10px;
//   > div {
//     display: flex;
//   }
//   &:hover {
//     color: #5aaeea;
//     cursor: pointer;
//     > div > svg > g {
//       stroke: #5aaeea;
//     }
//   }
//   ${({isActive}) => isActive && css`
//     color: #5aaeea;
//     > div > svg > g {
//       stroke: #5aaeea;
//     }
//   `}
//   svg {
//     padding-top: 2px;
//   }
// `;

// const ImageToolsContainer = styled.div`
//   padding: 10px 0;
//   display: flex;
//   justify-content: center;
//   gap: 0 25px;

//   @media (max-width:1440px) and (min-width:1280px) {
//     justify-content: flex-start;
//     gap: 0 5px;
//   }
// `;

// const LineTools = styled.div<{isModal: boolean}>`
//   font-size: 14px;
//   flex-grow: 1;
//   position: relative;
//   ${({isModal}) => isModal && css`
//     background: #fff;
//     border-bottom-left-radius: 5px;
//     border-bottom-right-radius: 5px;
//   `}
//   @media (max-width:1440px) and (min-width:1280px) {
//     display: flex;
//     justify-content: space-around;
//   }
// `;

const LineFormatter = styled.div`
`;

// const Formatter = styled.div`
// `;

const ImageFormatter = styled.div`
  img{
    max-height: calc(100vh - 100px);
    max-width: calc(100vw - 100px);
  }
  svg{
    max-height: calc(100vh - 100px);
    max-width: calc(100vw - 100px);
  }
`;

const ImageNone = styled.img`
  height: 300px;
  min-width: 420px;
  object-fit: cover;
`;


interface ILinePoints {
  points: IVector2[],
  changeLineStyle: boolean
}

interface IImageResponse {
  data: ArrayBuffer,
  status: number,
}

const LineViewer: FC<ILineViewer> = ({
  streamName,
  linesData = [],
  // width = '440px',
  width = '370px',
  height = '310px',
  isModal = false,
  isLineConfigurable = false,
  onLineChange = () => {},
  onImageClick = () => {},
  onMediaLoadedCallback = () => {},
  boundingBoxes,
  isStopFrame,
  showImage,
  setShowImage,
}) => {
  const [state, dispatch] = useReducer(LineReducer, []);
  const [isLiveFeed, setIsLiveFeed] = useState<boolean>(false);
  const [imageDetails, setImageDetails] = useState({x: 1920, y: 1080});
  const [prevImageDetails, setPrevImageDetails] = useState(imageDetails);
  const [imageLoading, setImageLoading] = useState<boolean>(true);
  const [image, setImage] = useState('');
  const [noImage, setNoImage] = useState(false);
  // const pathUrl = boundingBoxes ? '/websocket-sink-tracking/socket.io': '/websocket-sink/socket.io' ;
  const pathUrl = boundingBoxes ? '': '' ;
  const { loadingFrame, frame, action: { stopFrame, startFrame } } = useWebsocketFrame(pathUrl, streamName);
  const { isMediaUrlValid } = useMediaModal();
  const { t } = useTranslation(['CommonDict']);
  const [isLiveFeedMount, setIsLiveFeedMount] = useState<boolean>(true);
  const [isLinesMount, setIsLinesMount] = useState<boolean>(true);
  const lang = i18n.language === 'ja' ? 'ja' : 'en';
  const imageRef = useRef<HTMLDivElement>(null);
  const [modalState, setModalState] = useState<boolean>(isModal);

  const staticImg = useContext(MyContext);

  useEffect(()=>{
    setModalState(isModal);
  }, [isModal, modalState]);

  useClickOutside(imageRef, () => {
    setModalState(false);
    setShowImage(false);
  });

  const handleButtonClick = () => {
    setModalState(true);
  };

  const getLinePoints = useCallback((points: IVector2[]): ILinePoints => {
    let changeLineStyle = false;
    const boundaryOffset = Math.round(imageDetails.x * 2.5 / 100); // 48

    if (image === '') {
      return {points, changeLineStyle};
    }

    // checks if any point goes outside the boundary of image and trims those points
    if (
      (points[0].x < boundaryOffset || points[0].x > (imageDetails.x - boundaryOffset)) ||
      (points[0].y < boundaryOffset || points[0].y > (imageDetails.y - boundaryOffset)) ||
      (points[1].x < boundaryOffset || points[1].x > (imageDetails.x - boundaryOffset)) ||
      (points[1].y < boundaryOffset || points[1].y > (imageDetails.y - boundaryOffset))
    ) {
      changeLineStyle = true;
    }
    points[0].x = points[0].x < boundaryOffset ? boundaryOffset : points[0].x > (imageDetails.x - boundaryOffset) ? (imageDetails.x - boundaryOffset) : points[0].x;
    points[0].y = points[0].y < boundaryOffset ? boundaryOffset : points[0].y > (imageDetails.y - boundaryOffset) ? (imageDetails.y - boundaryOffset) : points[0].y;
    points[1].x = points[1].x < boundaryOffset ? boundaryOffset : points[1].x > (imageDetails.x - boundaryOffset) ? (imageDetails.x - boundaryOffset) : points[1].x;
    points[1].y = points[1].y < boundaryOffset ? boundaryOffset : points[1].y > (imageDetails.y - boundaryOffset) ? (imageDetails.y - boundaryOffset) : points[1].y;
    
    return {points, changeLineStyle};
  }, [image, imageDetails]);

  useEffect(() => {
    if (isLinesMount || linesData) {
      const state = linesData?.map(item => {
        if (item.styling === 'danger') {
          return {...item, readOnly: isLineConfigurable ? item.readOnly : true, showSmallDirectionMark: true, showPointHandle: isLineConfigurable ? item.showPointHandle : false};
        }
        const point = cloneDeep(item.points);
        const { points, changeLineStyle } =  getLinePoints(point);
        return {...item, points, styling: changeLineStyle ? 'danger' : 'primary', readOnly: isLineConfigurable ? item.readOnly : true, showSmallDirectionMark: true, showPointHandle: isLineConfigurable ? item.showPointHandle : false};
      });
      dispatch({
        type: 'LOAD',
        state
      });
      setIsLinesMount(false);
    }
  }, [isLinesMount, linesData, isLineConfigurable, getLinePoints]);

  useEffect(() => {
    if (state.length !== 0 && !isLinesMount) {
      onLineChange(state);
    }
  }, [state, isLinesMount, onLineChange]);

  useEffect(() => {
    if (!isEqual(imageDetails, prevImageDetails)) {
      setIsLinesMount(true);
      setPrevImageDetails(imageDetails);
    }
  }, [imageDetails, prevImageDetails]);

  useEffect(() => {
    onMediaLoadedCallback(image !== '' || !(imageLoading || noImage));
  }, [image, imageLoading, noImage, onMediaLoadedCallback]);
  
  const getCameraImage = useCallback(async () => {
    try {
      const res: IImageResponse = await axios.get(`${EDGE_API_BASE_URL}stacks/${streamName}/snapshot?timestamp=${Date.now()}`, { responseType: 'arraybuffer' });
      // const res: IImageResponse = await axios.get(`http://192.168.30.15:20001/api/v1/stacks/FTPCam1/snapshot?timestamp=${Date.now()}`, { responseType: 'arraybuffer' });
      if (res.status === 200 && res.data) {
        const {byteLength} = res.data;
        if(window.location.href.endsWith('/overview') && byteLength === 5){
          const timer = setTimeout(()=>{
            getCameraImage();
          }, 2000);
          if( byteLength !== 5){
            clearTimeout(timer);
          }
        }
        const imgBase64 = 'data:image/jpg;base64,' + Buffer.from(res.data).toString('base64');
        const isImageValid = await isMediaUrlValid(imgBase64, 'img');
        if (isImageValid === true) {
          const img = new Image();
          img.src = imgBase64;
          setImage(imgBase64);
          setImageDetails({
            x: img.naturalWidth,
            y: img.naturalHeight
          });
          setImageLoading(false);
          setNoImage(false);
        } else {
          setImageLoading(false);
          setNoImage(true);
        }
      } else {
        setImageLoading(false);
        setNoImage(true);
      }
    } catch (err) {
      setNoImage(true);
      setImageLoading(false);
      console.error(err);
    }
  }, [isMediaUrlValid, streamName]);


  usePoll(async () => {
    if (!isLiveFeed) {
      await getCameraImage();
    }
  }, LINE_VIEWER_CAMERA_IMAGE_REFRESH_INTERVAL * 1000);

  const onLiveFeed = useCallback(() => {
    setIsLiveFeed(true);
    startFrame(image);
  }, [image, startFrame]);



  useEffect(()=>{
    if(isStopFrame){
      stopFrame();
    }else {
      if(isLiveFeed){
        startFrame(image);
      }
    }
  }, [isStopFrame, stopFrame, startFrame, image, isLiveFeed]);

  useEffect(() => {
    if (isLiveFeedMount && (image !== '' || !(imageLoading || noImage))) {
      onLiveFeed();
      setIsLiveFeedMount(false);
    }
  }, [isLiveFeedMount, image, imageLoading, noImage, onLiveFeed]);

  const handleDismiss =()=>{};


  
  return (
    <Container {...{width, height}}>
      <LineUIImageWrapper isClickable={!(isModal || imageLoading || noImage)} isLineConfigurable={isLineConfigurable} onClick={() => {!noImage && onImageClick();}}>
        <LineSetContext.Provider value={{ state, dispatch }}>
          {
            imageLoading ?
              <SpinnerContainer>
                <Spinner size='large' styling='primary' />
              </SpinnerContainer>
              : image === '' && staticImg === 'liveImg' && (noImage || (isLiveFeed && !loadingFrame && frame === '')) ? 
                <SpinnerContainer {...{ backgroundColor: '#ddd' }}>
                  {lang==='ja' ?
                    <ImageNone src={AwaitingStreamJp} /> : <ImageNone src={AwaitingStreamEn} />}
                </SpinnerContainer>
                :
                <>
                  <div onClick={handleButtonClick}>
                    <LineUI
                      options={{
                        showSetIndex: false,
                        showPoint: true,
                        showDirectionMark: false,
                        showMoveHandle: false,
                        showPointHandle: true,
                        setIndexOffset: 0,
                        boundaryOffset: Math.round(imageDetails.x * 2.5 / 100),
                        fixedImgDimensions: imageDetails
                      }}
                      src={staticImg === 'liveImg' ? (isLiveFeed && frame !== '' ? frame : image) : staticImg}
                      hasClickSensingBorder={false}
                    />
                  </div>  
                </>
          }
          
          {((modalState || isModal) && showImage) &&
            <BoxModal
              closeText={t('CLOSE')}
              isOpen={modalState && showImage}
              onDismiss={()=> handleDismiss()}
              customeComponent={
                <LineFormatter>
                  <LineUIImageWrapper isClickable={!(isModal || imageLoading || noImage)} isLineConfigurable={isLineConfigurable} onClick={() => {!isLineConfigurable && onImageClick();}}>
                    <div ref={imageRef}>
                      <LineSetContext.Provider value={{ state, dispatch }}>
                        {showImage &&
                          <ImageFormatter>
                            <LineUI
                              options={{
                                showSetIndex: false,
                                showPoint: true,
                                showDirectionMark: false,
                                showMoveHandle: false,
                                showPointHandle: true,
                                setIndexOffset: 0,
                                boundaryOffset: Math.round(imageDetails.x * 2.5 / 100),
                                fixedImgDimensions: imageDetails
                              }}
                              src={staticImg === 'liveImg' ?  (isLiveFeed && frame !== '' ? frame : image) : staticImg}
                              hasClickSensingBorder={false}
                            />
                          </ImageFormatter>}
                      </LineSetContext.Provider>
                    </div>
                  </LineUIImageWrapper>  
                </LineFormatter>
              }
            />}
            
        </LineSetContext.Provider>
      </LineUIImageWrapper>
    </Container>
  );
};

export default LineViewer;
import React from 'react';
import logoMarkSvg from './logo-mark-dark.svg';
export const LoginScreenLogo: React.FC = () =>{
  return(
    <svg width='340' height='100' viewBox='0 0 340 60' xmlns='http://www.w3.org/2000/svg'>
      <g fill='#FFF' fillRule='evenodd'>
        <image href={logoMarkSvg} x='0' y='-10' height='85' width='85' />
        <g fillRule='nonzero'>
          <text fontSize='22' fontWeight='500'>
            <tspan x='90' y='20'>SCORER</tspan>
          </text>
        </g>
        <g fillRule='nonzero'>
          <text fontSize='22' fontWeight='500'>
            <tspan x='90' y='50'>Area Counter</tspan>
          </text>
        </g>
      </g>
    </svg>

  );
};

import React, { FC, useCallback, useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { EDGE_API_BASE_URL, LINE_VIEWER_CAMERA_IMAGE_REFRESH_INTERVAL } from '../constants';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { LineReducer, LineSetContext, LineUI, useModal, usePoll, useMediaModal, Spinner} from 'scorer-ui-kit';
import { LineUIOptions } from 'scorer-ui-kit/dist/LineUI';
import styled, { css } from 'styled-components';
import MediaModal from './MediaModal';
import i18n from 'i18n';
import AwaitingStreamEn from '../svg/img_awaiting_stream_en.jpg';
import AwaitingStreamJp from '../svg/img_awaiting_stream_jp.jpg';
import { LineChart, Line, ReferenceLine, YAxis, } from 'recharts';

const Container = styled.div<{ disabled: boolean }>`
  border-radius: 5px;
  display: grid;
  height: 250px;
  width: 300px;
  position: relative;
  object-fit: cover;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  ${({ disabled }) => disabled && css`
    opacity: 0.5;
  `}
  @media (max-width:1438px) and (min-width:1280px) {
    width:48.7%;
  }
`;

const ImageBox = styled.div<{ showPointer:boolean }>`
  width: 300px;
  position: relative;
  background: #ddd;
  margin: 0;
  padding: 0;
  @media only screen and (min-width: 1439px) {
    width: 300px;
  }
  height: 169px;
  border-radius: 5px;
  > div {
    height: inherit;
    > img {
      height: inherit;
      border-radius: 5px;
    }
  }
  ${({ showPointer }) => showPointer && css`
    cursor: pointer;
  `}
`;

const DetailsBox = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 86px;
  background: white;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  @media (max-width:1438px) and (min-width:1280px) {
    min-width: 400px;
    box-shadow: 1px 1px #cccc;
  }
  border-bottom-left-radius: 5px;   
  border-bottom-right-radius: 5px;
  display: grid;
  grid-template-columns: 30% 30% 40%;
  grid-template-rows: 50% 50%;
`;

const VerticalLine = styled.div`
  width: 1px;
  height: 26px;
  padding: 6px;
  border-left: 1px solid rgba(0, 0, 0, 0.08);
  cursor: pointer;
  margin-top: 89px;
  margin-left: 10px;
`;

const LeftDetailsBox = styled.div`
  grid-column: 1 / span 2;
  grid-row: 1 / span 1;
  margin-top: 52px;
  margin-left: 7px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  
`;

const CenterDetailsBox = styled.div`
  margin-top: 77px;  
  pointer-events: none;
`;

const RightDetailsBox = styled.div`
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  margin-top: 44px;
  margin-left: 195px;
  pointer-events: none;
`;

const CenterDetailsBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-left: -108px;
  pointer-events: none;
`;

const NoData = styled.div`
  width: 80px;
  height: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const LabelText = styled.p`
  font-size: 16px;
  padding-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit;
  margin: 0px;
`;

const LabelTextSmall = styled(LabelText)`
  font-size: 10px;
  color: #928686;
  margin-top: 10px;
`;

const LabelTextMedium = styled(LabelText)`
  font-size: 12px;
  color: #695f5f;
`;


const IconBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const LineBox = styled.div`
  width: 40px;
  height: 100%;
  padding: 11px;
  border-left: 1px solid rgba(0, 0, 0, 0.08);
  cursor: pointer;
`;


const CameraTextBox = styled.div`
  flex: 1;
  cursor: pointer;
  pointer-events: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  z-index: 1;
`;

const SpinnerContainer = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  height: 100%;
  border-radius: 3px;
  ${({ backgroundColor }) => css`
    background-color: ${backgroundColor? backgroundColor: '#a6a6a6'}
  `};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  @media (max-width:1438px) and (min-width:1280px) {
    height: 253px;
  }
`;

const FormatterSvg = styled.div`
  @media (max-width:1438px) and (min-width:1280px) {
    svg {
      width: 90%;
    }
  }
  display: flex;
    justify-content: space-around;
`;

const ImageNone = styled.img`
  height: 300px;
  object-fit: cover;
`;

const LineChartContainer = styled(LineChart)<{ isCrossed: boolean }>`
  background-color: ${({ isCrossed }) => isCrossed && '#fff4f4'};
`;

interface ICard {
  options?: LineUIOptions;
  data: any;
}

interface IImageResponse {
  data: ArrayBuffer,
  status: number,
}


const Card: FC<ICard> = ({ options = {}, data }) => {
  const [showLine, setShowLine] = useState<boolean>(true);
  const [state, dispatch] = useReducer(LineReducer, []);
  const [image, setImage] = useState('');
  const [imageDetails, setImageDetails] = useState({x: 1920, y: 1080});
  const [noImage, setNoImage] = useState(false);
  const { t } = useTranslation(['CommonDict']);
  const { createModal, setModalOpen } = useModal();
  const { push } = useHistory();
  const { isMediaUrlValid } = useMediaModal();
  const [imageLoading, setImageLoading] = useState<boolean>(true);
  const lang = i18n.language === 'ja' ? 'ja' : 'en';
  const [areaData, setAreaData] = useState<any>({'graph_data': []});
  const [isCrossed, setIsCrossed] = useState<boolean>(false);
  



  useEffect(() => {
    if (data.line_configuration.length===0) return;
    const defaultAreaData = {'graph_data':[], 'analysis_type':'', 'detection_threshold': 0, 'segmentation_threshold': 0, 'is_use_segmentation_area': 0};

    const pinned_area_id = data?.pinned_area[0];
    const targetAreaData = data.line_configuration.filter((item: any) => item.id === pinned_area_id)[0] || data.line_configuration[0] || defaultAreaData;
    setAreaData(targetAreaData);

    const lastGraphData = targetAreaData.graph_data[targetAreaData.graph_data.length - 1];
    if (lastGraphData){
      if (targetAreaData.analysis_type.toLowerCase() === 'ratio' && lastGraphData.ratio_value){
        setIsCrossed(lastGraphData.ratio_value >= targetAreaData.segmentation_threshold);
      }else if (targetAreaData.analysis_type.toLowerCase() === 'count' && targetAreaData.is_use_segmentation_area === 1){
        setIsCrossed(lastGraphData.segmentation_count >= targetAreaData.detection_threshold);
      } else{
        setIsCrossed(lastGraphData.area_count >= targetAreaData.detection_threshold);
      }
    }
  },[data]);


  useEffect(() => {
    const cameraName = localStorage.getItem('showLine_'+ data?.camera_name.toString());
    Object.entries(localStorage).map((i: string[]) => {
      if(i[0] === 'showLine_'+ data?.camera_name.toString()) {
        setShowLine(cameraName === 'true');
      }
      return i;
    });
  },[data]);

  const getCameraImage = useCallback(async () => {
    try {
      const res: IImageResponse = await axios.get(`${EDGE_API_BASE_URL}stacks/${data.stream_name}/snapshot?timestamp=${Date.now()}`, { responseType: 'arraybuffer' });
      if (res.status === 200 && res.data) {
        if (res.data.byteLength > 5) {
          const imgBase64 = 'data:image/jpg;base64,' + Buffer.from(res.data).toString('base64');
          const isImageValid = await isMediaUrlValid(imgBase64, 'img');
          if (isImageValid === true) {
            const img = new Image();
            img.src = imgBase64;
            setImage(imgBase64);
            setImageDetails({
              x: img.naturalWidth,
              y: img.naturalHeight
            });
            setNoImage(false);
            setImageLoading(false);
          }
        } else {
          setImage('');
          setNoImage(true);
          setImageLoading(false);
        }
      } else {
        setNoImage(true);
        setImageLoading(false);
        setImageLoading(false);
      }
    } catch (err) {
      setNoImage(true);
      console.error(err);
    }
  }, [data, isMediaUrlValid]);

  usePoll(async () => {
    await getCameraImage();
  }, (LINE_VIEWER_CAMERA_IMAGE_REFRESH_INTERVAL * 100));
  

  const onClickToShowLine = useCallback(() => {
    if (!noImage) {
      const showLineStatus = !showLine;
      localStorage.setItem('showLine_' + data?.camera_name, showLineStatus.toString());
      setShowLine(prev => !prev);
    } else {
      return false;
    }
  }, [noImage, showLine, data]);

  const goToCameraDetail = useCallback(() => {
    //if (data.status.status_category !== 'running' && data.status.status_category !== 'stopped' ) return;
    push({
      pathname: `/cameras/camera-details/${data.stream_name}/overview`,
      state: {
        line_configuration: data.line_configuration,
      }
    });
  }, [push, data]);


  const openMediaModel = useCallback((streamName: string) => { 
    createModal({
      isCloseEnable: true,
      width: '60%',
      padding: false,
      closeText: t('CLOSE'),
      dismissCallback: () => { setModalOpen(false); },
      customComponent: (<MediaModal {...{ state, dispatch, image, options, streamName }} bufferTime={(data?.camera_type === 'RTSP' ? data?.video_configuration?.buffer_time : LINE_VIEWER_CAMERA_IMAGE_REFRESH_INTERVAL) * 1000} />)
    });
  }, [data, state, dispatch, image, options, createModal, setModalOpen, t]);


  return (
    <Container disabled={data.status_code === 20100}>
      <ImageBox
        showPointer={!noImage}
        onClick={() => { (!noImage) && openMediaModel(data.stream_name); }}
      >
        {noImage ?
          <FormatterSvg>
            {lang==='ja' ?
              <ImageNone src={AwaitingStreamJp} /> : <ImageNone src={AwaitingStreamEn} />}
          </FormatterSvg>
          : 
          <>
            {imageLoading ?
              <SpinnerContainer>
                <Spinner size='large' styling='primary' />
              </SpinnerContainer> :
              <LineSetContext.Provider value={{ state, dispatch }}>
                <LineUI 
                  options={{...options, boundaryOffset: Math.round(imageDetails.x * 2.5 / 100)}} 
                  src={image} 
                  hasClickSensingBorder={false}
                />
              </LineSetContext.Provider>}
          </>}
      </ImageBox>
      <DetailsBox>
        <LeftDetailsBox>
          <CameraTextBox onClick={goToCameraDetail}>
            <LabelText title={t(data?.camera_name)}>{t(data?.camera_name)}</LabelText>
          </CameraTextBox>
          <LabelTextSmall>{t('Detection')}</LabelTextSmall>
          <LabelTextMedium>{t(data?.status.status_category)}</LabelTextMedium>
        </LeftDetailsBox>
        <CenterDetailsBoxContainer>
          <VerticalLine />
          <CenterDetailsBox>
            <LabelTextSmall>{t('Last Updated')}</LabelTextSmall>
            <LabelTextMedium>{data?.latest_record_time_str}</LabelTextMedium>
          </CenterDetailsBox>
        </CenterDetailsBoxContainer>
        <RightDetailsBox>            
          <IconBox>
            <LineBox onClick={onClickToShowLine} title={t('Click')}>
              {areaData.graph_data.length !== 0 ? 
                <LineChartContainer
                  width={80} height={50}
                  data={areaData.graph_data}
                  isCrossed={isCrossed}
                >
                  <YAxis 
                    width={0}
                    domain={[
                      0, 
                      areaData.analysis_type.toLowerCase() === 'ratio' ?
                        Math.max(
                          Math.max(...areaData.graph_data.map((item: any) => item.ratio_value)),
                          areaData.segmentation_threshold + 1
                        )
                        :
                        Math.max(
                          Math.max(
                            ...areaData.graph_data.map((item: any) => areaData.is_use_segmentation_area === 1 ? item.segmentation_count : item.area_count)
                          ), 
                          areaData.detection_threshold + 1
                        )
                    ]}
                  />
                  <Line
                    type='linear' stroke='#a3c0ec' strokeWidth={2} dot={false}
                    dataKey={
                      areaData.analysis_type.toLowerCase() === 'ratio' ? 'ratio_value'
                        : (areaData.analysis_type.toLowerCase() === 'count' && areaData.is_use_segmentation_area === 1) ? 'segmentation_count' : 'area_count'
                    }
                  />
                  
                  <ReferenceLine
                    stroke='red' strokeDasharray='3 3'
                    y={areaData.analysis_type.toLowerCase() === 'ratio' ? areaData.segmentation_threshold : areaData.detection_threshold}
                  />
                </LineChartContainer>
                : <NoData>No Data</NoData>}
            </LineBox>
          </IconBox>
        </RightDetailsBox>
      </DetailsBox>
    </Container>
  );
};

export default Card;